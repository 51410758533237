import replace from "lodash/replace";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postAuthCode } from "../api/auth";
import { postMembers } from "../api/members";
import { POSTALPINE_PRIVACY_LINK, POSTALPINE_TEMRS_LINK } from "../common/data";
import { images } from "../common/images";
import { LogoHeader } from "../components/headers";
import MainLayout from "../layouts";

export const Signup = () => {
  const session = window.sessionStorage;
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [signupCode, setSignupCode] = useState<string>("");

  const [showCodeInput, setShowCodeInput] = useState<boolean>(false);
  const [authDisabled, setAuthDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<"인증요청" | "재전송">(
    "인증요청"
  );

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (phone.length < 11) {
      setAuthDisabled(true);
    } else {
      setAuthDisabled(false);
    }
  }, [phone]);

  useEffect(() => {
    if (
      username.includes("@") &&
      password.length >= 8 &&
      name !== "" &&
      phone.length === 11 &&
      code.length === 6 &&
      termsAgreed &&
      privacyAgreed
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [username, password, name, phone, code, termsAgreed, privacyAgreed]);

  const _onClickAuth = async () => {
    setAuthDisabled(true);

    const codeData = await postAuthCode({ phone });
    if (codeData) {
      setShowCodeInput(true);
      setSignupCode(codeData.id);
      setButtonText("재전송");
    } else {
      setPhone("");
    }

    setAuthDisabled(false);
  };

  const _submit = async () => {
    setButtonDisabled(true);

    const body = {
      username,
      password,
      name,
      phone: signupCode,
      code: Number(code),
    };
    const postMembersData = await postMembers(body);
    if (postMembersData) {
      session.setItem("token", postMembersData.token);
      navigate("/", { replace: true });
    }
  };

  return (
    <MainLayout>
      <LogoHeader />
      <div className="px-[20px] pt-[32px] pb-[60px] bg-white h-full">
        <div className="flex flex-col gap-y-[20px] mb-[32px]">
          <div>
            <div className="mb-[8px] text-gray-70 px-[4px]">이메일</div>
            <input
              className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
              placeholder="사용하실 이메일 주소를 입력해주세요"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="email"
            />
          </div>

          <div>
            <div className="mb-[8px] text-gray-70 px-[4px]">비밀번호</div>
            <input
              className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
              placeholder="영문+숫자 조합 최소 8자리 이상 입력해주세요"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>

          <div>
            <div className="mb-[8px] text-gray-70 px-[4px]">이름</div>
            <input
              className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
              placeholder="이름을 입력해주세요"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>

          <div>
            <div className="mb-[8px] text-gray-70 px-[4px]">연락처</div>
            <div className="bg-gray-5 h-[52px] px-[16px] rounded-[8px] flex items-center justify-between">
              <input
                className="h-full w-full bg-gray-5 text-[16px] placeholder:text-gray-40 outline-none"
                placeholder="'-'제외 숫자만 입력해주세요"
                value={phone}
                onChange={(e) =>
                  setPhone(replace(e.target.value, /[^0-9]/g, ""))
                }
                maxLength={11}
                type="text"
              />
              <button
                className="text-white min-w-[58px] text-[12px] font-[600] bg-green-50 h-[26px] rounded-[6px] ml-[12px]"
                onClick={_onClickAuth}
                disabled={authDisabled}
              >
                {buttonText}
              </button>
            </div>
            {showCodeInput && (
              <input
                className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none mt-[8px]"
                placeholder="인증번호 6자리"
                value={code}
                onChange={(e) =>
                  setCode(replace(e.target.value, /[^0-9]/g, ""))
                }
                maxLength={6}
                type="text"
              />
            )}
          </div>
        </div>

        <div>
          <div className="mb-[8px]">
            필수 약관 <span className="text-error-50">*</span>
          </div>

          <div className="flex items-center mb-[12px]">
            <button
              className="w-[24px] h-[24px] mr-[8px]"
              onClick={() => setTermsAgreed(!termsAgreed)}
            >
              <img
                src={
                  termsAgreed
                    ? images.checkbox.square.filled
                    : images.checkbox.square.empty
                }
                alt=""
              />
            </button>
            <span className="mr-[4px]">[필수]</span>
            <div className="grow">이용약관에 동의합니다.</div>
            <button
              className="flex items-center"
              onClick={() => {
                window.open(POSTALPINE_TEMRS_LINK);
              }}
            >
              <span className="text-[12px] text-gray-40 underline">자세히</span>
              <img
                src={images.chevron.right.gray}
                className="w-[16px] h-[16px]"
                alt=""
              />
            </button>
          </div>

          <div className="flex">
            <button
              className="w-[24px] h-[24px] mr-[8px]"
              onClick={() => setPrivacyAgreed(!privacyAgreed)}
            >
              <img
                src={
                  privacyAgreed
                    ? images.checkbox.square.filled
                    : images.checkbox.square.empty
                }
                alt=""
              />
            </button>
            <span className="mr-[4px]">[필수]</span>
            <div className="grow">개인정보처리방침에 동의합니다.</div>
            <button
              className="flex items-center"
              onClick={() => {
                window.open(POSTALPINE_PRIVACY_LINK);
              }}
            >
              <span className="text-[12px] text-gray-40 underline">자세히</span>
              <img
                src={images.chevron.right.gray}
                className="w-[16px] h-[16px]"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white px-[20px] py-[20px] border-t border-t-gray-10">
        <button
          className={`w-full h-[48px] rounded-[8px] ${
            buttonDisabled
              ? "bg-gray-20 text-gray-40"
              : "bg-green-50 text-white"
          }`}
          disabled={buttonDisabled}
          onClick={_submit}
        >
          회원가입
        </button>
      </div>
    </MainLayout>
  );
};
