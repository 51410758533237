import { RawAxiosRequestHeaders } from "axios";
import { axios, baseUrl } from "../common/configs/axios";

export const postDelivery = async (
  headers: RawAxiosRequestHeaders,
  body: PostDeliveryAddressPayload
) => {
  try {
    const response = await axios.post(baseUrl + "/delivery-addresses", body, {
      headers,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};

export const getDelivery = async (headers: RawAxiosRequestHeaders) => {
  try {
    const response = await axios.get(baseUrl + "/delivery-addresses/latest", {
      headers,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};
