import { RawAxiosRequestHeaders } from "axios";
import { axios, baseUrl } from "../common/configs/axios";

export const postOrder = async (
  headers: RawAxiosRequestHeaders,
  body: RequestOrderPayload
) => {
  try {
    const response = await axios.post(baseUrl + "/order-groups", body, {
      headers,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};

export const completeOrder = async (
  headers: RawAxiosRequestHeaders,
  body: CompleteOrderPayload
) => {
  try {
    const response = await axios.put(
      baseUrl + `/order-groups/${body.orderId}/complete`,
      { portoneId: body.portoneId },
      {
        headers,
      }
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};
