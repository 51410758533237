import { RawAxiosRequestHeaders } from "axios";
import { axios, baseUrl } from "../common/configs/axios";

export const getUser = async (headers: RawAxiosRequestHeaders) => {
  try {
    const response = await axios.get(baseUrl + "/members/me", { headers });
    return response;
  } catch (err: any) {
    console.log(err.response.data);
  }
};

export const postMembers = async (body: PostMembersPayload) => {
  try {
    const response = await axios.post(baseUrl + "/members", body);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      if (response.status === 409) {
        alert("이미 가입한 계정이 존재합니다.");
      } else {
        alert(`오류\n\n${response.data.message}`);
      }
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};
