import queryString from "query-string";
import { format, parseISO } from "date-fns";
import { ko } from "date-fns/locale";

export const getDiscountRatio = (originalPrice: number, discount: number) => {
  return ((100 * discount) / originalPrice).toFixed(0);
};

let qs = queryString.parse(window.location.search);
if (qs.utm_source === undefined) {
  qs.utm_source = "landing";
}
qs.utm_content = "landing";
let utmTag =
  Object.keys(qs).length === 0 ? "" : "?" + queryString.stringify(qs);

export const downloadIOS = () => {
  window.open(
    "https://apps.apple.com/kr/app/%EA%B7%B8%EB%A6%B0%ED%95%98%EB%8B%A4-%EC%B4%88%EC%A0%80%EA%B0%80-%EB%86%8D%EC%82%B0%EB%AC%BC-%EB%8F%84%EB%A7%A4-%EC%96%B4%ED%94%8C/id6468979011" +
      utmTag
  );
};

export const downloadAOS = () => {
  window.open(
    "https://play.google.com/store/apps/details?id=com.postalpine.greenhada&" +
      utmTag.slice(1, utmTag.length)
  );
};

export const downloadApp = () => {
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i)
  ) {
    downloadIOS();
  } else {
    downloadAOS();
  }
};

export const formatTime = (dateString: string) => {
  return format(parseISO(dateString), "yyyy-MM-dd hh:mm:ss", {
    locale: ko,
  });
};
