import { axios, baseUrl } from "../common/configs/axios";

export const login = async (requestBody: LoginPayload) => {
  try {
    const response = await axios.post(baseUrl + "/auth/login", requestBody);

    if (response.status >= 200 && response.status < 300) {
      console.log("login success");
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert("오류\n\n존재하지 않는 계정입니다.");
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};

export const postAuthCode = async (body: PostAuthCodePayload) => {
  try {
    const response = await axios.post(baseUrl + "/auth/phone", body);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};
