import { track } from "@amplitude/analytics-browser";
import { replace } from "lodash";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getBanks } from "../../api/banks";
import { getDelivery } from "../../api/delivery";
import { getUser } from "../../api/members";
import { postOrder } from "../../api/order";
import {
  POSTALPINE_PRIVACY_LINK,
  POSTALPINE_TEMRS_LINK,
} from "../../common/data";
import { images } from "../../common/images";
import { LogoHeader } from "../../components/headers";
import { Loader } from "../../components/loader";
import MainLayout from "../../layouts";

interface PaymentMethodProps {
  focus: "card" | "trans" | "vbank";
}
enum PaymentMap {
  "card" = "카드 결제",
  "trans" = "계좌이체",
  "vbank" = "무통장 입금",
}

export const Order = () => {
  const session = window.sessionStorage;
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const [products, setProducts] = useState<BasketItemsResponse>([]);
  const [user, setUser] = useState<UserInfoResponse>();
  const [deliveryData, setDeliveryData] = useState<DeliveryAddressResponse>();
  const [banks, setBanks] = useState<GetBanksResponse>([]);

  const [spreadBanks, setSpreadBanks] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [method, setMethod] = useState<
    "card" | "trans" | "vbank" | undefined
  >();
  const [holder, setHolder] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");

  const [originalPriceSum, setOriginalPriceSum] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addressReady, setAddressReady] = useState<boolean>(false);

  useEffect(() => {
    track("enter_checkout_web");
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (token && state && state.products) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const products = state && state.products ? state.products : [];
        setProducts(products);

        const userResponse = await getUser(headers);
        if (
          userResponse &&
          userResponse.status >= 200 &&
          userResponse.status < 300
        ) {
          setUser(userResponse.data);
          const [deliveryData, banksData] = await Promise.all([
            getDelivery(headers),
            getBanks(headers),
          ]);

          if (deliveryData) {
            setDeliveryData(deliveryData);
            setAddressReady(true);
          }
          if (banksData) {
            setBanks(banksData);
          }
        } else if (userResponse == null || userResponse.status === 401) {
          session.removeItem("token");
          navigate("login", { replace: true });
        }

        var originalPriceSum = 0;
        var finalPrice = 0;

        // eslint-disable-next-line array-callback-return
        products.map((item: BasketItem) => {
          originalPriceSum += item.product.originalPrice * item.quantity;
          finalPrice += item.product.price * item.quantity;
        });
        setOriginalPriceSum(originalPriceSum);
        setFinalPrice(finalPrice);
      }
      setLoaded(true);
    };

    fetch();
  }, [session, token, navigate, state, products, location]);

  useEffect(() => {
    if (finalPrice <= 0) {
      setButtonDisabled(true);
    } else {
      if (addressReady) {
        if (method === "card" || method === "trans") {
          setButtonDisabled(false);
        } else if (method === "vbank") {
          if (selectedBank == null || holder === "" || accountNumber === "") {
            setButtonDisabled(true);
          } else {
            setButtonDisabled(false);
          }
        }
      } else {
        setButtonDisabled(true);
      }
    }
  }, [accountNumber, addressReady, finalPrice, holder, method, selectedBank]);

  const _editAddress = () => {
    navigate("/order/address");
  };

  const _payCallback = async (response: any) => {
    if (response.success) {
      navigate(
        `../order/finish?imp_uid=${response.imp_uid}&merchant_uid=${response.merchant_uid}&imp_success=true`
      );
    } else {
      navigate(
        `../order/finish?imp_uid=${response.imp_uid}&merchant_uid=${response.merchant_uid}&imp_success=false&error_msg=%EC%A3%BC%EB%AC%B8%20%EA%B2%B0%EC%A0%9C%EC%97%90%20%EC%8B%A4%ED%8C%A8%ED%95%98%EC%98%80%EC%8A%B5%EB%8B%88%EB%8B%A4`
      );
    }
  };

  const _onClickPay = async () => {
    setIsLoading(true);
    setButtonDisabled(true);

    track("click_checkout_web");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      var body: any = {
        method,
        deliveryAddressId: deliveryData!!.id,
        products: products.map((product) => ({
          id: product.product.id,
          quantity: product.quantity,
        })),
      };
      if (method === "vbank") {
        body = {
          ...body,
          bankAccount: {
            bankId: selectedBank!!.id,
            number: accountNumber,
            holder,
          },
        };
      }

      const response = await postOrder(headers, body);
      if (response) {
        console.log("from server", response);
        if (window.IMP) {
          const { IMP } = window;
          IMP.init(response.userCode);
          IMP.request_pay(
            {
              ...response.data,
              m_redirect_url: window.location.origin + "/order/finish",
            },
            _payCallback
          );
        }
      }
    }

    setButtonDisabled(false);
    setIsLoading(false);
  };

  const OrderItem = ({ item }: { item: BasketItem }) => {
    return (
      <div className="w-full bg-white flex flex-row items-center">
        <div className="rounded-[4px] mr-[12px] justify-center">
          <img
            src={item.product.thumbnail}
            className="rounded-[4px] w-[86px] aspect-square"
            alt=""
          />
        </div>

        <div className="flex flex-col flex-1 p-[8px]">
          <div className="flex flex-row mb-[4px]">
            <div className="flex flex-col flex-1 mr-[8px]">
              <div className="min-h-[42px]">
                <div>
                  {item.product.title}
                  {item.product.title}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-x-[8px]">
              <div className="flex flex-row gap-x-[4px]">
                <div className="text-gray-70">{item.quantity}개</div>
                <div className="text-gray-40">ㅣ</div>
                <div className="line-through text-gray-40">
                  {item.product.originalPrice?.toLocaleString()}원
                </div>
              </div>
              <div>{item.product.price.toLocaleString()}원</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PaymentMethodBox = ({ focus }: PaymentMethodProps) => {
    return (
      <button
        className={`py-[12px] items-center rounded-[8px] border ${
          method === focus ? "border-green" : "border-gray-20"
        }`}
        onClick={() => setMethod(focus)}
      >
        {method === focus ? (
          <div className="font-[600] text-green">{PaymentMap[focus]}</div>
        ) : (
          <div>{PaymentMap[focus]}</div>
        )}
      </button>
    );
  };

  if (token == null) return <Navigate to="../login" />;
  else
    return loaded ? (
      <MainLayout>
        {isLoading && <Loader />}
        <LogoHeader />
        <div className="bg-white px-[20px] pt-[24px] pb-[32px]">
          {/* 주문자 정보 */}
          <div>
            <div className="font-[600] mb-[12px]">주문자 정보</div>
            <div className="flex items-center text-[14px] text-gray-70">
              <span>{user?.name}</span>
              <div className="w-[1px] h-[12px] bg-gray-20 mx-[8px]" />
              <span>{user?.phone}</span>
            </div>
          </div>

          <div className="h-[1px] bg-gray-10 my-[32px]" />

          {/* 배송 정보 */}
          <div>
            <div className="flex flex-row justify-between items-center mb-[4px]">
              <div className="font-[600] mb-[12px]">배송 정보</div>
              <button
                className="p-[8px] mt-[-8px] mr-[-8px]"
                onClick={_editAddress}
              >
                <div className="px-[8px] py-[4px] rounded-[6px] border border-green">
                  <div className="text-green font-[600] text-[12px]">
                    {addressReady ? "변경하기" : "추가하기"}
                  </div>
                </div>
              </button>
            </div>
            {addressReady ? (
              <>
                <div className="flex flex-row items-center mb-[8px] text-[14px] text-gray-70">
                  <span>{deliveryData?.name}</span>
                  <div className="w-[1px] h-[12px] mx-[8px] bg-gray-20" />
                  <span>{deliveryData?.phone}</span>
                </div>

                <div className="text-[14px] text-gray-70 mb-[8px]">
                  {`(${deliveryData?.zipcode}) ${
                    deliveryData?.primaryAddress
                  } ${deliveryData?.secondaryAddress ?? ""}`}
                </div>

                <div className="text-[13px] text-gray-40">
                  배송 요청 사항 :{" "}
                  {deliveryData?.request && deliveryData?.request !== ""
                    ? deliveryData?.request
                    : "없음"}
                </div>
              </>
            ) : (
              <>
                <div className="text-[14px] text-gray-70">
                  등록된 배송지가 없습니다.
                </div>
              </>
            )}
          </div>

          <div className="h-[1px] bg-gray-10 my-[32px]" />

          {/* 주문 상품 */}
          <div>
            <div className="font-[600] mb-[12px]">주문 상품</div>
            <div>
              {products.length > 0
                ? products.map((product: BasketItem, index: number) =>
                    index === 0 ? (
                      <OrderItem key={index} item={product} />
                    ) : (
                      <div key={index} className="mt-[22px]">
                        <OrderItem item={product} />
                      </div>
                    )
                  )
                : null}
            </div>
          </div>

          <div className="h-[1px] bg-gray-10 my-[32px]" />

          {/* 결제 수단 */}
          <div>
            <div className="font-[600] mb-[12px]">결제 수단 선택</div>
            <div className="flex  flex-col gap-y-[8px]">
              <PaymentMethodBox focus="card" />
              <PaymentMethodBox focus="trans" />
              <PaymentMethodBox focus="vbank" />
            </div>
          </div>
          {method === "vbank" && (
            <div className="flex flex-col gap-y-[8px]">
              <div className="mt-[24px] mb-[12px] font-[600]">
                환불 계좌 정보
              </div>
              <div style={{ zIndex: 99 }}>
                <button
                  className="bg-white h-[52px] w-full px-[16px] rounded-[8px] flex items-center border border-gray-20"
                  onClick={() => setSpreadBanks(!spreadBanks)}
                >
                  <div
                    className={`grow text-left ${
                      selectedBank ? "" : "text-gray-40"
                    }`}
                  >
                    {selectedBank?.name ?? "은행 선택"}
                  </div>
                  <img
                    src={
                      spreadBanks
                        ? images.chevron.up.black
                        : images.chevron.down.black
                    }
                    className="w-[24px] h-[24px]"
                    alt=""
                  />
                </button>
                {spreadBanks && (
                  <div className="bg-white max-h-[150px] overflow-y-scroll scrollbar-none border flex flex-col gap-y-[4px] mt-[8px] rounded-[8px] p-[8px] mb-[-158px]">
                    {banks.map((bank, index) => (
                      <button
                        key={index}
                        className={`min-h-[52px] min-w-full px-[16px] rounded-[8px] flex items-center ${
                          bank === selectedBank ? "bg-gray-10" : "text-gray-70"
                        }`}
                        onClick={() => {
                          setSelectedBank(bank);
                          setSpreadBanks(false);
                        }}
                      >
                        {bank.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <input
                className="border border-gray-20 h-[48px] rounded-[8px] px-[16px] outline-none placeholder:text-gray-40"
                placeholder="예금주 입력"
                value={holder}
                onChange={(e) => setHolder(e.target.value)}
                type="text"
              />

              <input
                className="border border-gray-20 h-[48px] rounded-[8px] px-[16px] outline-none placeholder:text-gray-40"
                placeholder="계좌번호 입력"
                value={accountNumber}
                onChange={(e) =>
                  setAccountNumber(replace(e.target.value, /[^0-9]/g, ""))
                }
                type="text"
              />
            </div>
          )}

          <div className="h-[1px] bg-gray-10 my-[32px]" />

          <div>
            <div className="font-[600] mb-[12px]">결제 정보</div>
            <div className="flex flex-col gap-y-[10px]">
              <div className="flex flex-row justify-between">
                <div className="text-gray-70">상품 금액</div>
                <div>{originalPriceSum?.toLocaleString()}원</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-gray-70">상품 할인</div>
                <div className="text-error">
                  -{(originalPriceSum - finalPrice)?.toLocaleString()}원
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-gray-70">배송비</div>
                <div>0원</div>
              </div>
            </div>
          </div>

          <div className="h-[1px] bg-gray-10 my-[32px]" />
          <div className="flex flex-row items-center justify-between">
            <div className="text-gray-70">결제예정금액</div>
            <div className="font-[800] text-green">
              {finalPrice.toLocaleString()}원
            </div>
          </div>
        </div>

        <div className="mt-[8px] px-[20px] pt-[32px] pb-[60px] bg-white text-[12px]">
          <div className="flex flex-row justify-between mb-[4px]">
            <div>서비스 이용약관</div>
            <button onClick={() => window.open(POSTALPINE_TEMRS_LINK)}>
              <div className="text-gray-70 underline">보기</div>
            </button>
          </div>
          <div className="flex flex-row justify-between">
            <div>개인정보 수집 동의</div>
            <button onClick={() => window.open(POSTALPINE_PRIVACY_LINK)}>
              <div className="text-gray-70 underline">보기</div>
            </button>
          </div>

          <div className="h-[1px] bg-gray-10 mt-[24px] mb-[16px]" />

          <div>위 내용을 확인 하였으며 결제에 동의합니다.</div>
        </div>

        <div className="p-[16px] bg-white border-t border-t-gray-10">
          <button
            className={`w-full h-[56px] rounded-[8px] font-[600] ${
              buttonDisabled ? "bg-gray text-gray-20" : "bg-green text-white"
            }`}
            disabled={buttonDisabled}
            onClick={_onClickPay}
          >
            {finalPrice.toLocaleString()}원 결제하기
          </button>
        </div>
      </MainLayout>
    ) : (
      <Loader />
    );
};
