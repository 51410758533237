import { useEffect, useState } from "react";
// import Slider from "react-slick";
import {
  getHotDealProducts,
  getProducts,
  getWeeklySaleProducts,
} from "../api/products";
import { getDiscountRatio } from "../common/utils";
import { MainHeader } from "../components/headers";
import MainLayout from "../layouts";

import { track } from "@amplitude/analytics-browser";
import ReactPixel from "react-facebook-pixel";
import { useNavigate } from "react-router-dom";
import { addBasket } from "../api/baskets";
import { images } from "../common/images";
import BottomTab from "../components/bottom-tab";
import { MainFooter } from "../components/footers";

export const Main = () => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState<boolean>(false);

  const [products, setProducts] = useState([]);
  const [hotDealProducts, setHotDealProducts] = useState([]);
  const [weeklySaleProducts, setWeeklySaleProducts] = useState([]);

  useEffect(() => {
    track("enter_greenhada_web");
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const [productsData, hotDealProductsData, weeklySaleProductsData] =
        await Promise.all([
          getProducts(),
          getHotDealProducts(),
          getWeeklySaleProducts(),
        ]);
      if (productsData) {
        setProducts(productsData);
      }
      if (hotDealProductsData) {
        setHotDealProducts(hotDealProductsData);
      }
      if (weeklySaleProductsData) {
        setWeeklySaleProducts(weeklySaleProductsData);
      }

      setLoaded(true);
    };

    fetch();
  }, []);

  const _addBasket = async (productId: number) => {
    const token = window.sessionStorage.getItem("token");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      track("add_basket", {
        product_id: productId,
      });

      const response = await addBasket(headers, { productId, quantity: 1 });
      if (response) {
        alert("장바구니에 상품을 추가하였습니다.");
      }
    } else {
      navigate("../login");
    }
  };

  // const carouselSettings = {
  //   arrows: false,
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slideToShow: 1,
  //   slidesToScroll: 1,
  // };

  return loaded ? (
    <MainLayout>
      <MainHeader />

      {/* 이벤트 배너 */}
      {/* {events.length > 0 && (
        <div className="min-h-[280px] bg-white">
          <Slider {...carouselSettings}>
            {events.map((event: any, index) => (
              <button
                key={index}
                onClick={() => {
                  track("click_herobanner");
                  _showCtaAlert(
                    "웹브라우저에서는 이벤트에 참여하실 수 없습니다.\n그린하다 앱 설치 후 참여해 주세요."
                  );
                }}
              >
                <img src={event.image} alt="" />
              </button>
            ))}
          </Slider>
        </div>
      )} */}

      {/* 100원딜 */}
      <div className="bg-white">
        <div className="px-[20px] pt-[32px] pb-[16px]">
          <div className="mb-[8px]">
            <span className="text-[20px] font-[600]">
              첫 구매 특가 100원부터!
            </span>
          </div>
          <div className="text-[14px] font-[500] text-gray-70">
            그린하다 인기 상품을 100원 ~ 1,990원에 구매해 보세요.
          </div>
        </div>

        <div className="flex flex-row overflow-x-auto scrollbar-none pr-[20px] bg-white">
          {hotDealProducts.map((item: any, index) =>
            item.stock == null || (item.stock && item.stock > 0) ? (
              <div
                className={`${
                  index === 0 ? "ml-[20px]" : "ml-[9px]"
                } w-[163px]`}
                key={index}
              >
                <>
                  <button
                    className="mb-[8px] w-[163px]"
                    onClick={() => {
                      track("click_product", { product_id: item.id });
                      navigate(`/product?q=${item.id}`, {
                        state: {
                          isEvent: true,
                        },
                      });
                    }}
                  >
                    <div>
                      <img
                        className="rounded-[4px] w-[163px] h-[163px]"
                        src={item.thumbnail}
                        alt=""
                      />
                    </div>

                    <div className="my-[8px] text-[14px] font-[400] text-start">
                      {item.title}
                    </div>

                    <div className={`flex flex-row items-center`}>
                      <div
                        className={`line-through text-[12px] font-[400] text-gray-40 mr-[8px]`}
                      >
                        {item.originalPrice?.toLocaleString()}원
                      </div>
                      <div className={`text-[14px] font-[600] text-error-50`}>
                        {item.price?.toLocaleString()}원
                      </div>
                    </div>
                  </button>
                </>
              </div>
            ) : null
          )}
        </div>
      </div>

      {/* 이번주 특가 할인 */}
      <div className="bg-white">
        <div className="px-[20px] pt-[32px] pb-[16px]">
          <div className="mb-[8px]">
            <span className="text-[20px] font-[600]">이번주 특가 할인!</span>
          </div>
          <div className="text-[14px] font-[500] text-gray-70">
            상품 불만족시 아무 조건 없이 즉시 100% 환불 보장
          </div>
        </div>

        <div className="flex flex-row overflow-y-auto scrollbar-none">
          {weeklySaleProducts.map((item: any, index) =>
            item.stock == null || (item.stock && item.stock > 0) ? (
              <div
                className={`${
                  index === 0 ? "ml-[20px]" : "ml-[9px]"
                } w-[163px] pr-[20px]`}
                key={index}
              >
                <>
                  <button
                    className="mb-[8px] w-[163px]"
                    onClick={() => {
                      track("click_product", { product_id: item.id });
                      navigate(`/product?q=${item.id}`, {
                        state: {
                          isEvent: true,
                        },
                      });
                    }}
                  >
                    <div>
                      <img
                        className="rounded-[4px] w-[163px] h-[163px]"
                        src={item.thumbnail}
                        alt=""
                      />
                    </div>

                    <div className="my-[8px] text-[14px] font-[400] text-start">
                      {item.title}
                    </div>

                    <div className={`flex flex-row items-center`}>
                      <div
                        className={`line-through text-[12px] font-[400] text-gray-40 mr-[8px]`}
                      >
                        {item.originalPrice?.toLocaleString()}원
                      </div>
                      <div className={`text-[14px] font-[600] text-error-50`}>
                        {item.price?.toLocaleString()}원
                      </div>
                    </div>
                  </button>
                </>
              </div>
            ) : null
          )}
        </div>
      </div>

      {/* 전체 상품 */}
      <div className="bg-white pb-[32px]">
        <div className="text-[20px] font-[600] flex justify-between px-[20px] pt-[24px] pb-[16px]">
          전체 상품
        </div>

        <div className="grid grid-cols-2 gap-x-[9px] gap-y-[24px] px-[20px]">
          {products.map((item: any, index) =>
            item.stock == null || (item.stock && item.stock > 0) ? (
              <div key={index}>
                <div className="flex flex-col">
                  <button
                    className="mb-[8px]"
                    onClick={() => {
                      track("click_product", { product_id: item.id });
                      navigate(`/product?q=${item.id}`);
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      style={{
                        borderRadius: 4,
                        width: "100%",
                        height: undefined,
                        aspectRatio: 1,
                      }}
                      alt=""
                    />
                  </button>
                  <button
                    className="mb-[8px] flex items-center justify-center gap-x-[4px] h-[32px] rounded-[4px] border border-gray-20"
                    onClick={() => {
                      _addBasket(item.id);
                    }}
                  >
                    <img
                      src={images.icon.basket}
                      className="w-[16px] h-[16px]"
                      alt=""
                    />
                    <span className="text-[13px]">장바구니 담기</span>
                  </button>
                  <button
                    onClick={() => {
                      track("click_product", { product_id: item.id });
                      navigate(`/product?q=${item.id}`);
                    }}
                  >
                    <div className="text-[14px] font-[400] text-start mb-[8px]">
                      {item.title}
                    </div>

                    <div className="text-[12px] font-[400] text-start line-through text-gray-40">
                      {item.originalPrice?.toLocaleString()}원
                    </div>

                    <div className="flex flex-row gap-x-[8px] mb-[4px]">
                      <div className="text-[#D83E35] text-[14px] font-[600] text-error-60 leading-[21px]">
                        {getDiscountRatio(
                          item.originalPrice,
                          item.originalPrice - item.price
                        )}
                        %
                      </div>
                      <div className="text-[14px] font-[600] leading-[21px]">
                        {item.price?.toLocaleString()}원
                      </div>
                    </div>

                    <div className="flex flex-row items-center gap-x-[8px]">
                      <div className="flex flex-row items-center gap-x-[2px]">
                        <img
                          src={images.icon.star}
                          className="w-[12px] h-[12px]"
                          alt=""
                        />
                        <div className="text-[12px] font-[400] text-gray-40">
                          {item.rating ? item.rating.toFixed(1) : 0}
                        </div>
                      </div>

                      <div className="flex flex-row items-center gap-x-[2px]">
                        <img
                          src={images.icon.message}
                          className="w-[12px] h-[12px]"
                          alt=""
                        />
                        <div className="text-[12px] font-[400] text-gray-40">
                          후기{" "}
                          {item.reviewCount > 999
                            ? "999+"
                            : item.reviewCount ?? 0}
                          개
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
      <MainFooter />
      <BottomTab />
    </MainLayout>
  ) : null;
};
