import { useLocation, useNavigate } from "react-router-dom";
import { LogoHeader } from "../../components/headers";
import MainLayout from "../../layouts";
import { useEffect, useState } from "react";
import { images } from "../../common/images";
import { formatTime } from "../../common/utils";
import queryString from "query-string";
import { Loader } from "../../components/loader";
import { completeOrder } from "../../api/order";
import { isMobile } from "react-device-detect";
import { track } from "@amplitude/analytics-browser";

export const Finish = () => {
  const { state } = useLocation();
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  const [amount, setAmount] = useState<string>("");
  const [dueAt, setDueAt] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>();

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      if (location.search) {
        const qs = queryString.parse(location.search) as Record<any, string>;
        console.log(qs);
        if (qs.imp_success === "true") {
          if (token) {
            const headers = { Authorization: `Bearer ${token}` };
            const completeResponse = await completeOrder(headers, {
              orderId: qs.merchant_uid,
              portoneId: qs.imp_uid,
            });
            console.log(completeResponse);
            if (completeResponse.success) {
              track("complete_payment");

              if (completeResponse.bankAccount) {
                setAmount(completeResponse.amount);
                setDueAt(completeResponse.dueAt);
                setBankName(completeResponse.bankAccount.bank.name);
                setAccountNumber(completeResponse.bankAccount.number);
              } else {
                setAmount(completeResponse.amount);
              }
            }
          }
          setLoaded(true);
        } else {
          alert(qs.error_msg);
          navigate(isMobile ? -2 : -1);
        }
      }
      setLoaded(true);
    };

    fetch();
  }, [state, location, navigate, token]);

  const _onClickCopy = async () => {
    await navigator.clipboard.writeText(`${bankName} ${accountNumber}`);
  };

  const _onClickFinish = () => {
    navigate("/");
  };

  return loaded ? (
    <MainLayout>
      <LogoHeader />
      <div className="px-[20px] grow flex flex-col justify-center bg-white">
        <div className="w-full flex justify-center">
          <div className="bg-[#F6F9F6] w-[100px] h-[100px] flex justify-center items-center rounded-full mt-[48px] mb-[24px]">
            <img src={images.icon.card} className="w-[68px] h-[68px]" alt="" />
          </div>
        </div>

        {accountNumber == null ? (
          <div className="text-gray-70 mb-[8px] text-center">
            상품 결제가 완료되었습니다.
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="text-gray-70 mb-[8px] text-center">
              상품 주문이 접수되었습니다.
            </div>
            <div className="text-[20px] text-center text-green font-[600]">
              {dueAt ? formatTime(dueAt as string) : ""} 까지
              <br />
              입금을 완료해주세요.
            </div>

            <div className="h-[1px] bg-gray-10 my-[48px]" />

            <div className="flex flex-col gap-y-[4px] text-[14px]">
              <div className="flex flex-row gap-x-[16px] items-center">
                <div className="text-gray-40">결제 금액</div>
                <div className="text-green font-[600]">
                  {Number(amount)?.toLocaleString()}원
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-x-[16px] items-center">
                  <div className="text-gray-40">입금 정보</div>
                  <div className="font-[600]">
                    {bankName} | {accountNumber}
                  </div>
                </div>
                <button
                  className="h-[24px] px-[8px] border border-green rounded-[6px] justify-center"
                  onClick={_onClickCopy}
                >
                  <div className="text-green text-[12px]">복사</div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="p-[16px] bg-white border-t border-t-gray-10">
        <button
          className="bg-green text-white w-full h-[56px] rounded-[8px] font-[600]"
          onClick={_onClickFinish}
        >
          홈으로
        </button>
      </div>
    </MainLayout>
  ) : (
    <Loader />
  );
};
