import { images } from "../common/images";

export const MainHeader = () => {
  return (
    <div className="min-h-[60px] flex justify-center items-center bg-white">
      <img src={images.logo.greenhada} className="w-[72px] h-[18px]" alt="" />
    </div>
  );
};

export const LogoHeader = () => {
  return (
    <div className="min-h-[60px] flex justify-center items-center bg-white">
      <button onClick={() => (window.location.href = "/")}>
        <img src={images.logo.greenhada} className="w-[72px] h-[18px]" alt="" />
      </button>
    </div>
  );
};
