import { track } from "@amplitude/analytics-browser";
import { filter, pull } from "lodash";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { flushBasket, getBasket, patchBasket } from "../../api/baskets";
import { images } from "../../common/images";
import { LogoHeader } from "../../components/headers";
import MainLayout from "../../layouts";

export const Basket = () => {
  const session = window.sessionStorage;
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();

  const [basket, setBasket] = useState<BasketItemsResponse>([]);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [originalPriceSum, setOriginalPriceSum] = useState<number>(0);
  const [discountPriceSum, setDiscountPriceSum] = useState<number>(0);
  const shipmentFee = 0;
  const [finalPrice, setFinalPrice] = useState<number>(0);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    track("enter_basket_web");
  }, []);

  // fetch initial basket data
  useEffect(() => {
    const fetch = async () => {
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const basketResponse = await getBasket(headers);
        if (basketResponse) {
          if (basketResponse.status === 401) {
            session.removeItem("token");
            navigate("/login", { replace: true });
          } else if (
            basketResponse.status >= 200 &&
            basketResponse.status < 300
          ) {
            setBasket(basketResponse.data);
            setSelectedProductIds(
              basketResponse.data.map((item: BasketItem) => item.id)
            );
          }
        }
      }
      setLoaded(true);
    };

    fetch();
  }, [token, session, navigate]);

  // button state
  useEffect(() => {
    if (finalPrice === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [finalPrice]);

  // calc prices
  useEffect(() => {
    var originalPriceSum = 0;
    var finalPrice = 0;
    if (basket.length > 0 && selectedProductIds.length > 0) {
      const filteredBasket = filter(basket, (item) =>
        selectedProductIds.includes(item.id)
      );
      // eslint-disable-next-line array-callback-return
      filteredBasket.map((item) => {
        originalPriceSum += item.product.originalPrice * item.quantity;
        finalPrice += item.product.price * item.quantity;
      });
    }
    setOriginalPriceSum(originalPriceSum);
    setDiscountPriceSum(originalPriceSum - finalPrice);
    setFinalPrice(finalPrice);
  }, [basket, selectedProductIds]);

  const _updateBasket = async () => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const basketResponse = await getBasket(headers);
      if (basketResponse) {
        if (basketResponse.status === 401) {
          session.removeItem("token");
          navigate("/login", { replace: true });
        } else if (
          basketResponse.status >= 200 &&
          basketResponse.status < 300
        ) {
          setBasket(basketResponse.data);
        }
      }
    }
  };

  const _selectAll = () => {
    if (basket.length > 0 && selectedProductIds.length > 0) {
      setSelectedProductIds([]);
    } else {
      setSelectedProductIds(basket.map((item) => item.id));
    }
  };

  const _decreaseBasket = async (id: number, quantity: number) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await patchBasket(headers, id, {
        quantity: quantity > 1 ? quantity - 1 : 1,
      });
      if (response) {
        await _updateBasket();
        return true;
      } else {
        return false;
      }
    }
  };

  const _increaseBasket = async (id: number, quantity: number) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await patchBasket(headers, id, {
        quantity: quantity + 1,
      });
      if (response) {
        await _updateBasket();
        return true;
      } else {
        return false;
      }
    }
  };

  const _flushBasket = async () => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      setBasket(basket.filter((item) => !selectedProductIds.includes(item.id)));
      setSelectedProductIds([]);

      selectedProductIds.map(async (productId) => {
        await flushBasket(headers, productId);
      });
    }
  };

  const _flushItem = async (id: number) => {
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      setBasket(basket.filter((item) => item.id !== id));
      setSelectedProductIds(pull(Object.assign([], selectedProductIds), id));
      const response = await flushBasket(headers, id);
      if (response == null) {
        setBasket(basket);
        setSelectedProductIds(selectedProductIds);
      }
    }
  };

  const _onClickOrder = async () => {
    track("click_order");

    navigate("/order", {
      state: {
        products: basket.filter((item) => selectedProductIds.includes(item.id)),
      },
    });
  };

  const BasketItem = ({ item }: { item: BasketItem }) => {
    const [count, setCount] = useState<number>(item.quantity);

    const _onClickThumbnail = async () => {
      if (selectedProductIds.includes(item.id)) {
        setSelectedProductIds(
          pull(Object.assign([], selectedProductIds), item.id)
        );
      } else {
        setSelectedProductIds([...selectedProductIds, item.id]);
      }
    };

    const _onDecrease = async () => {
      if (count > 1) {
        setCount(count - 1);
        const response = await _decreaseBasket(item.id, count);
        if (!response) {
          setCount(count);
        }
      }
    };

    const _onIncrease = async () => {
      setCount(count + 1);
      const response = await _increaseBasket(item.id, count);
      if (!response) {
        setCount(count);
      }
    };

    return (
      <div className="w-full bg-white flex flex-row">
        <button
          className="rounded-[4px] mr-[12px] flex justify-center"
          onClick={_onClickThumbnail}
        >
          <img
            src={
              selectedProductIds.includes(item.id)
                ? images.checkbox.filled
                : images.checkbox.empty
            }
            className="mr-[-22px] mt-[4px] w-[18px] h-[18px]"
            style={{ zIndex: 99 }}
            alt=""
          />
          <img
            src={item.product.thumbnail}
            className="rounded-[4px] w-[86px] aspect-square"
            alt=""
          />
        </button>

        <div className="flex flex-col flex-1">
          <div className="flex flex-row mb-[4px]">
            <button
              className="flex flex-1 mr-[8px]"
              onClick={() => {
                navigate("/product?q=" + item.product.id);
              }}
            >
              <div className="min-h-[42px]">
                <div className="font-[600] text-left">{item.product.title}</div>
              </div>
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-center mr-[-7px] mt-[-7px]"
              onClick={() => _flushItem(item.id)}
            >
              <img
                src={images.xMark.gray}
                className="w-[16px] h-[16px]"
                alt=""
              />
            </button>
          </div>

          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-x-[8px]">
              <div className="line-through text-gray-40">
                {item.product.originalPrice?.toLocaleString()}원
              </div>
              <div>{item.product.price?.toLocaleString()}원</div>
            </div>

            <div className="flex flex-row items-center">
              <button onClick={_onDecrease} disabled={count <= 1}>
                <img
                  src={
                    count > 1 ? images.minusBox.abled : images.minusBox.disabled
                  }
                  className="w-[18px] h-[18px]"
                  alt=""
                />
              </button>
              <div className="mx-[6px]">{count}</div>
              <button onClick={_onIncrease}>
                <img
                  src={images.plusBox}
                  className="w-[18px] h-[18px]"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (token == null) return <Navigate to="../login" />;
  else
    return loaded ? (
      <MainLayout>
        <LogoHeader />
        {basket.length > 0 ? (
          <div className="flex flex-col h-full">
            <div className="h-[72px] flex flex-row items-center justify-between px-[12px] bg-white">
              <div className="flex flex-row items-center">
                <button
                  className="w-[44px] h-[44px] mr-[-9px] flex items-center justify-center"
                  onClick={_selectAll}
                >
                  <img
                    src={
                      basket && basket.length === selectedProductIds.length
                        ? images.checkbox.filled
                        : images.checkbox.empty
                    }
                    className="w-[18px] h-[18px]"
                    alt=""
                  />
                </button>
                <div>전체선택</div>
              </div>

              <button className="p-[8px]" onClick={_flushBasket}>
                <div className="text-[14px]">선택 삭제</div>
              </button>
            </div>

            <div className="h-[8px] bg-gray-5" />

            <div className="px-[20px] pt-[32px] pb-[48px] bg-white">
              {basket.map((item, index) =>
                index === 0 ? (
                  <BasketItem key={index} item={item} />
                ) : (
                  <div key={index}>
                    <div className="h-[1px] bg-gray-10 my-[32px]" />
                    <BasketItem item={item} />
                  </div>
                )
              )}
            </div>

            <div className="h-[8px] bg-gray-5" />

            <div className="bg-white px-[20px] pt-[48px] pb-[60px] grow">
              <div className="mb-[16px]">결제 정보</div>

              <div className="flex flex-col gap-y-[8px]">
                <div className="flex flex-row justify-between">
                  <div className="text-gray-70">상품 금액</div>
                  <div>{originalPriceSum?.toLocaleString()}원</div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="text-gray-70">상품 할인</div>
                  <div className="text-error">
                    -{discountPriceSum?.toLocaleString()}원
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="text-gray-70">배송비</div>
                  <div>{shipmentFee?.toLocaleString()}원</div>
                </div>
              </div>

              <div className="h-[1px] bg-gray-10 my-[16px]" />

              <div className="flex flex-row justify-between items-center">
                <div className="text-gray-70">결제예정금액</div>
                <div className="text-[18px]">
                  {finalPrice?.toLocaleString()}원
                </div>
              </div>
            </div>

            <div className="p-[16px] bg-white border-t border-t-gray-10">
              <button
                className={`w-full h-[56px] rounded-[8px] font-[600] ${
                  buttonDisabled
                    ? "bg-gray text-gray-20"
                    : "bg-green text-white"
                }`}
                disabled={buttonDisabled}
                onClick={_onClickOrder}
              >
                {finalPrice.toLocaleString()}원 주문하기
              </button>
            </div>
          </div>
        ) : (
          <div className="grow flex flex-col justify-center items-center bg-white">
            <div className="w-[100px] h-[100px] flex flex-col items-center justify-center bg-green-10 rounded-full mb-[32px]">
              <img
                src={images.emptyBasket}
                className="w-[72px] h-[72px]"
                alt=""
              />
            </div>
            <div className="flex flex-col items-center mb-[8px] font-[600]">
              <div>장바구니에</div>
              <div>담긴 상품이 없습니다.</div>
            </div>
            <button className="p-2">
              <div className="rounded-[8px] border border-gray-40 px-4 py-2">
                <div
                  className="font-[600] text-[14px]"
                  onClick={() => navigate("/")}
                >
                  상품 구매하기
                </div>
              </div>
            </button>
          </div>
        )}
      </MainLayout>
    ) : null;
};
