export const images = {
  banner: require("../assets/images/greenhada-banner.png"),
  logo: {
    greenhada: require("../assets/images/greenhada-logo.png"),
    appstore: require("../assets/images/appstore-logo.png"),
    playstore: require("../assets/images/playstore-logo.png"),
  },
  bottomTab: {
    basket: {
      inactive: require("../assets/images/basket-icon-inactive.png"),
    },
    home: {
      active: require("../assets/images/home-icon-active.png"),
      inactive: require("../assets/images/home-icon-inactive.png"),
    },
    profile: {
      active: require("../assets/images/profile-icon-active.png"),
      inactive: require("../assets/images/profile-icon-inactive.png"),
    },
  },
  icon: {
    basket: require("../assets/images/basket-icon.png"),
    card: require("../assets/images/card-icon.png"),
    star: require("../assets/images/star-icon.png"),
    greenhada: require("../assets/images/logo-small-green.png"),
    question: require("../assets/images/question-icon.png"),
    message: require("../assets/images/message-icon.png"),
    triangle: require("../assets/images/triangle.png"),
  },
  chevron: {
    right: {
      gray: require("../assets/images/chevron-right-gray.png"),
      black: require("../assets/images/chevron-right-black.png"),
    },
    down: {
      black: require("../assets/images/chevron-down.png"),
    },
    up: {
      black: require("../assets/images/chevron-up.png"),
    },
  },
  checkbox: {
    square: {
      filled: require("../assets/images/checkbox-square-filled.png"),
      empty: require("../assets/images/checkbox-square-empty.png"),
    },
    filled: require("../assets/images/checkbox-filled.png"),
    empty: require("../assets/images/checkbox-empty.png"),
  },
  xMark: {
    gray: require("../assets/images/x-mark-gray.png"),
  },
  minusBox: {
    abled: require("../assets/images/minusbox-abled.png"),
    disabled: require("../assets/images/minusbox-disabled.png"),
  },
  plusBox: require("../assets/images/plusbox.png"),

  profileImage: require("../assets/images/profile-image.png"),
  emptyBasket: require("../assets/images/empty-basket.png"),
};
