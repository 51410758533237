import { axios, baseUrl } from "../common/configs/axios";

export const getProducts = async () => {
  try {
    const response = await axios.get(baseUrl + "/products");
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      alert("오류");
    }
  } catch {
    alert("서버 오류");
  }
};

export const getProduct = async (id: number) => {
  try {
    const response = await axios.get(baseUrl + "/products/" + id);
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      alert("오류");
    }
  } catch {
    alert("서버 오류");
  }
};

export const getHotDealProducts = async () => {
  try {
    const response = await axios.get(baseUrl + "/events/2/products");
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      alert("오류");
    }
  } catch {
    alert("서버 오류");
  }
};

export const getWeeklySaleProducts = async () => {
  try {
    const response = await axios.get(baseUrl + "/events/3/products");
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      alert("오류");
    }
  } catch {
    alert("서버 오류");
  }
};
