import axiosInstance from "axios";

export const axios = axiosInstance.create({
  withCredentials: false,
  validateStatus: (status) => status < 500,
  // timeout: 5000,
});

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "/api"
    : "https://api.greenhada.store";