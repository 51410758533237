import replace from "lodash/replace";
import { useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useNavigate } from "react-router-dom";
import { postDelivery } from "../../api/delivery";
import { LogoHeader } from "../../components/headers";
import MainLayout from "../../layouts";

export const Address = () => {
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const open = useDaumPostcodePopup();

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [primaryAddress, setPrimaryAddress] = useState<string>("");
  const [secondaryAddress, setSecondaryAddress] = useState<string>("");
  const [request, setRequest] = useState<string>("");

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const _onClickSearch = () => {
    open({ onComplete: _onCompleteSearch });
  };

  const _onCompleteSearch = (data: any) => {
    console.log(data);
    setZipcode(data.zonecode);
    setPrimaryAddress(data.address);
  };

  const _submit = async () => {
    const body = {
      name,
      phone,
      zipcode,
      primaryAddress,
      secondaryAddress,
      request,
    };

    const response = await postDelivery(headers, body);
    if (response) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (
      name !== "" &&
      phone !== "" &&
      zipcode !== "" &&
      primaryAddress !== ""
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [name, phone, zipcode, primaryAddress]);

  return (
    <MainLayout>
      <LogoHeader />
      <div className="h-full bg-white px-[20px] pt-[24px] pb-[60px]">
        <div className="mb-[20px]">
          <div className="mb-[8px] text-gray-70 px-[4px]">받는 사람</div>
          <input
            className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
            placeholder="받는분의 성함을 입력해주세요"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
          />
        </div>

        <div className="mb-[20px]">
          <div className="mb-[8px] text-gray-70 px-[4px]">연락처</div>
          <input
            className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
            placeholder="받는분의 연락처를 입력해주세요"
            value={phone}
            onChange={(e) => setPhone(replace(e.target.value, /[^0-9]/g, ""))}
            maxLength={11}
            type="text"
          />
        </div>

        {primaryAddress ? (
          <>
            <div className="mb-[20px]">
              <div className="mb-[8px] text-gray-70 px-[4px]">배송 주소</div>
              <div className="h-[48px] bg-gray-5 flex items-center px-[16px] rounded-[8px] mb-[8px]">
                {primaryAddress}
              </div>
              <input
                className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
                placeholder="상세 주소를 입력해주세요"
                value={secondaryAddress}
                onChange={(e) => setSecondaryAddress(e.target.value)}
                type="text"
              />
            </div>

            <div>
              <div className="mb-[8px] text-gray-70 px-[4px]">
                <span>배송 요청사항</span>
                <span className="text-red">(선택)</span>
              </div>
              <input
                className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
                placeholder="배송 요청사항을 입력해주세요"
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                type="text"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="mb-[8px] text-gray-70 px-[4px]">배송 주소</div>
            <button
              className="text-green font-[600] border border-green w-full h-[48px] rounded-[8px]"
              onClick={_onClickSearch}
            >
              주소 검색
            </button>
          </div>
        )}
      </div>
      <div className="bg-white px-[20px] py-[20px] border-t border-t-gray-10">
        <button
          className={`w-full h-[48px] rounded-[8px] ${
            buttonDisabled
              ? "bg-gray-20 text-gray-40"
              : "bg-green-50 text-white"
          }`}
          disabled={buttonDisabled}
          onClick={_submit}
        >
          입력 완료
        </button>
      </div>
    </MainLayout>
  );
};
