import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { addBasket } from "../api/baskets";
import { getProduct } from "../api/products";
import { images } from "../common/images";
import { getDiscountRatio } from "../common/utils";
import { LogoHeader } from "../components/headers";
import MainLayout from "../layouts";

export const Product = () => {
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductDetailResponse>();

  const [searchParams] = useSearchParams();
  const productId = searchParams.get("q");
  const { state } = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
    track("enter_product_web", { product_id: productId });
  }, [productId]);

  useEffect(() => {
    const fetch = async () => {
      const productData = await getProduct(Number(productId));
      if (productData) {
        setProduct(productData);
      }
      setLoaded(true);
    };

    fetch();
  }, [productId]);

  const _onClickPurchase = async () => {
    track("click_purchase", { product_id: productId });
    ReactPixel.trackCustom("ClickPurchase", { productId });
    // downloadApp();
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (state && state.isEvent) {
        navigate("/order", {
          state: {
            products: [
              {
                product: {
                  id: product?.id,
                  originalPrice: product?.originalPrice,
                  price: product?.price,
                  store: {
                    id: product?.store.id,
                  },
                  thumbnail: product?.thumbnail,
                  title: product?.title,
                },
                quantity: 1,
              },
            ],
          },
        });
      } else {
        const basketResponse = await addBasket(headers, {
          productId: Number(productId),
          quantity: 1,
        });
        if (basketResponse) {
          navigate("/basket");
        }
      }
    } else {
      navigate("/login");
    }
  };

  return loaded && product ? (
    <MainLayout>
      <LogoHeader />
      <img src={product.thumbnail} className="w-full" alt="" />
      <div className="px-[20px] pt-[24px] pb-[20px] bg-white border-b border-b-gray-10">
        <div className="font-[600] text-[16px] text-gray-100 mb-[16px]">
          {product.title}
        </div>

        <div className="text-[14px] font-[400] line-through text-gray-40 mb-[2px]">
          {product.originalPrice?.toLocaleString()}원
        </div>

        <div className="flex items-center">
          <span className="text-[20px] font-[800] text-[#D83E35] mr-[8px]">
            {product.discount
              ? getDiscountRatio(product.originalPrice, product.discount)
              : 0}
            %
          </span>
          <span className="text-[20px] font-[800] text-gray-100">
            {product.price?.toLocaleString()}원
          </span>
        </div>

        <div className="mt-[8px]">
          <img
            src={images.icon.triangle}
            className="w-[12px] h-[10px] ml-[12px] mb-[-2px]"
            alt=""
          />
          <div className="bg-green-10 rounded-[4px] px-[12px] py-[8px]">
            <div className="text-[14px] font-[600] text-green-50 mb-[4px]">
              최저가 200% 보상제
            </div>
            <div className="text-[12px] font-[400] text-green-50">
              그린하다에서 결제한 금액이 타 오픈마켓에서 동일 조건으로 결제한
              금액보다 비싼 경우 차액의 200%를 쿠폰으로 보상해드립니다.
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-[20px] flex mb-[8px]">
        <span className="mr-[12px] text-gray-40 text-[12px] font-[400]">
          배송정보
        </span>
        <span className="flex flex-col gap-y-[4px] text-[12px] font-[600] text-gray-100">
          <div>무료배송</div>
          <div>택배배송 • CJ대한통운</div>
        </span>
      </div>

      <div className="px-[20px] pt-[20px] pb-[60px] bg-white">
        <div className="text-center text-[16px] font-[600] text-gray-90 mb-[24px]">
          상품 정보
        </div>
        <div className="flex  flex-col gap-y-[20px]">
          {product.images.map((image: string, index: number) => (
            <img src={image} className="w-full" alt="" key={index} />
          ))}
        </div>
      </div>

      <div className="sticky bottom-0 border-t border-t-gray-10 bg-white p-[16px]">
        <button
          className="w-full bg-green-50 h-[56px] rounded-[8px] text-[16px] font-[600] text-white"
          onClick={_onClickPurchase}
        >
          구매하기
        </button>
      </div>
    </MainLayout>
  ) : null;
};
