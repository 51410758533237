import { RawAxiosRequestHeaders } from "axios";
import { axios, baseUrl } from "../common/configs/axios";

export const getBasket = async (headers: RawAxiosRequestHeaders) => {
  try {
    const response = await axios.get(baseUrl + "/baskets", { headers });

    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status >= 400 && response.status < 500) {
      return response;
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};

export const addBasket = async (
  headers: RawAxiosRequestHeaders,
  requestBody: AddBasketItemPayload
) => {
  try {
    const response = await axios.post(baseUrl + "/baskets", requestBody, {
      headers,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};

export const patchBasket = async (
  headers: RawAxiosRequestHeaders,
  productId: number,
  requestBody: PatchBasketItemPayload
) => {
  try {
    const response = await axios.patch(
      baseUrl + "/baskets/" + productId,
      requestBody,
      {
        headers,
      }
    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};

export const flushBasket = async (
  headers: RawAxiosRequestHeaders,
  productId: number
) => {
  try {
    const response = await axios.delete(baseUrl + "/baskets/" + productId, {
      headers,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    console.log(err.response.data);
    alert("서버 오류");
  }
};
