import { POSTALPINE_PRIVACY_LINK, POSTALPINE_TEMRS_LINK } from "../common/data";
import { images } from "../common/images";

export const MainFooter = () => {
  return (
    <div className="bg-gray-10 text-gray-70 py-[32px] px-[16px] h-fit">
      <a href="http://pf.kakao.com/_NZcuG/chat">
        <div className="w-fit flex py-[9.5px] px-[12px] bg-white border-[1px] border-solid text-gray-90 border-gray-20 text-[14px] leading-[24px] tracking-[-0.07px] rounded-[8px] align-middle">
          <img
            src={images.icon.question}
            alt=""
            className="mr-[4px] w-[24px] h-[24px]"
          />
          고객센터
        </div>
      </a>
      <div className="mt-[8px] text-[12px]">
        고객센터 운영시간 : 10:00 ~ 19:00 (주말, 공휴일 제외)
      </div>

      <div className="mt-[24px] h-[1px] bg-gray-20"></div>

      <div className="flex pt-[24px]">
        <a href={POSTALPINE_TEMRS_LINK}>
          <div className="font-bold text-[13px] tracking-[-0.5px] leading-[150%]">
            서비스 이용 약관
          </div>
        </a>

        <a href={POSTALPINE_PRIVACY_LINK}>
          <div className="ml-[24px] font-bold text-[13px] tracking-[-0.5px] leading-[150%]">
            개인정보처리방침
          </div>
        </a>
      </div>

      <div className="mb-[90px]">
        <div className="mt-[24px] whitespace-pre-wrap text-[12px] tracking-[-0.5px] leading-[18px]">
          {/* (주) 포스트알파인  |  사업자등록번호 : 518-86-01993  |  대표이사 : 김지현<br/> */}
          (주) 포스트알파인 | 사업자등록번호 : 518-86-01993 | 대표자 : 김지현 |
          통신판매업 신고번호 : 2023-서울마포-3205 <br />
          주소 : 서울특별시 마포구 마포대로 122, 11층(신용보증기금빌딩) <br />
          전화번호 : 02-2088-4661 <br />
          <br />© Postalpine Inc. All rights reserved.
        </div>
      </div>
    </div>
  );
};
