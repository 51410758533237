import { track } from "@amplitude/analytics-browser";
import { images } from "../common/images";
import { downloadAOS, downloadIOS } from "../common/utils";

interface MainLayoutProps {
  children?: React.ReactNode;
}

const CtaSection = () => {
  return (
    <div className="w-[300px] ml-[0px]">
      <div className="h-screen flex flex-col justify-center fixed">
        <div className="text-center text-[32px] text-green-50 font-[700]">
          온라인 최저가 신선 식품
        </div>
        <div className="text-center text-[32px] font-[700] mb-[24px]">
          환불 보장, 무료 배송
        </div>
        <div className="bg-green-50 rounded-[20px] mb-[16px]">
          <img src={images.banner} className="w-[300px] h-[300px]" alt="" />
        </div>
        <div className="text-[22px] text-gray-80 font-[600] text-center mb-[32px]">
          지금 앱에서 간편하게 구매하세요
        </div>
        <div className="flex flex-row justify-center gap-x-[8px]">
          <button
            onClick={() => {
              track("click_download_android");
              downloadAOS();
            }}
          >
            <img
              src={images.logo.playstore}
              className="w-[134px] h-[40px]"
              alt=""
            />
          </button>
          <button
            onClick={() => {
              track("click_download_ios");
              downloadIOS();
            }}
          >
            <img
              src={images.logo.appstore}
              className="w-[134px] h-[40px]"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const MainLayout = ({ children }: MainLayoutProps) => {
  return window.innerWidth > 490 ? (
    window.innerWidth > 1080 ? (
      <div className="flex justify-center">
        <div className="flex flex-row gap-x-[150px]">
          <div className="w-[490px] flex flex-col min-h-screen">{children}</div>
          <CtaSection />
        </div>
      </div>
    ) : (
      <div className="flex justify-center">
        <div className="flex flex-row w-[490px]">
          <div className="flex flex-col w-[490px] min-h-screen">{children}</div>
        </div>
      </div>
    )
  ) : (
    <div className="flex justify-center">
      <div className="flex flex-col w-[100%]">
        <div className="flex flex-col min-h-screen">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
