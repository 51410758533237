import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getUser } from "../api/members";
import { images } from "../common/images";
import BottomTab from "../components/bottom-tab";
import { LogoHeader } from "../components/headers";
import MainLayout from "../layouts";
import { downloadApp } from "../common/utils";

export const MyPage = () => {
  const session = window.sessionStorage;
  const navigate = useNavigate();

  const token = session.getItem("token");
  const [user, setUser] = useState<UserInfoResponse>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const _redirect = (message: string) => {
    alert(message);
    downloadApp();
  };

  useEffect(() => {
    const fetch = async () => {
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const userResponse = await getUser(headers);
        if (userResponse) {
          console.log(userResponse.data);
          if (userResponse.status >= 200 && userResponse.status < 300) {
            setUser(userResponse.data);
          } else {
            if (userResponse.status === 401) {
              session.removeItem("token");
              navigate("/login", { replace: true });
            }
          }
        }
        setLoaded(true);
      }
    };

    fetch();
  }, [navigate, session, token]);

  if (token == null) return <Navigate to="../login" />;
  else
    return loaded ? (
      <MainLayout>
        <LogoHeader />
        <div className="h-full bg-white">
          <div className="px-[20px] pt-[24px] pb-[32px] bg-white mb-[8px]">
            <div className="flex flex-row gap-x-[16px] items-center mb-[20px]">
              <img
                src={images.profileImage}
                className="w-[64px] h-[64px]"
                alt=""
              />
              <div className="flex flex-col gap-y-[2px]">
                <div className="font-[600]">{user?.name}님</div>
                <div className="text-gray-40 text-[13px]">{user?.username}</div>
              </div>
            </div>
          </div>

          <div className="h-[8px] bg-gray-5" />

          <div className="flex flex-col px-[20px] pt-[32px] pb-[60px] bg-white">
            <div className="mb-[40px]">
              <div className="text-[12px] font-[800] text-gray-70 mb-[24px]">
                마이 그린
              </div>
              <div className="flex flex-col gap-y-[4px]">
                <button
                  className="flex flex-row justify-between items-center py-[8px]"
                  onClick={() =>
                    _redirect(
                      "주문 내역을 확인하시려면 그린하다 앱을 설치해주세요."
                    )
                  }
                >
                  <div className="flex flex-row gap-x-[16px]">
                    <img
                      src={images.icon.greenhada}
                      className="w-[24px] h-[24px]"
                      alt=""
                    />
                    <div>주문 내역</div>
                  </div>
                  <img
                    src={images.chevron.right.gray}
                    className="w-[24px] h-[24px]"
                    alt=""
                  />
                </button>
                <button
                  className="flex flex-row justify-between items-center py-[8px]"
                  onClick={() =>
                    _redirect(
                      "구매 후기를 작성하시려면 그린하다 앱을 설치해주세요."
                    )
                  }
                >
                  <div className="flex flex-row gap-x-[16px]">
                    <img
                      src={images.icon.star}
                      className="w-[24px] h-[24px]"
                      alt=""
                    />
                    <div>구매 후기</div>
                  </div>
                  <img
                    src={images.chevron.right.gray}
                    className="w-[24px] h-[24px]"
                    alt=""
                  />
                </button>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-[12px] font-[800] text-gray-70 mb-[24px]">
                편의 기능
              </div>
              <div className="flex flex-col gap-y-[4px]">
                <button
                  className="flex flex-row justify-between items-center py-[8px]"
                  onClick={() => {
                    window.open("https://pf.kakao.com/_NZcuG/chat");
                  }}
                >
                  <div className="flex flex-row gap-x-[16px]">
                    <img
                      src={images.icon.question}
                      className="w-[24px] h-[24px]"
                      alt=""
                    />
                    <div>고객센터</div>
                  </div>
                  <img
                    src={images.chevron.right.gray}
                    className="w-[24px] h-[24px]"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <BottomTab />
      </MainLayout>
    ) : null;
};
