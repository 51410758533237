import { useLocation, useNavigate } from "react-router-dom";
import { images } from "../common/images";

const BottomTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const session = window.sessionStorage;

  const _onClickHome = () => {
    if (location.pathname !== "/") navigate("/");
  };

  const _onClickBasket = () => {
    navigate("/basket");
  };

  const _onClickProfile = () => {
    if (location.pathname !== "/my-page") navigate("/my-page");
  };

  return (
    <div className="sticky bottom-0 h-[52px] flex flex-row border-t border-gray-10 justify-center px-[24px] bg-white">
      {/* 홈 */}
      <button
        className="flex flex-1 items-center justify-center"
        onClick={_onClickHome}
      >
        <img
          src={
            location.pathname === "/"
              ? images.bottomTab.home.active
              : images.bottomTab.home.inactive
          }
          className="w-8 h-8"
          alt=""
        />
      </button>

      {/* 장바구니 */}
      <button
        className="flex flex-1 items-center justify-center"
        onClick={_onClickBasket}
      >
        <img
          src={images.bottomTab.basket.inactive}
          className="w-8 h-8"
          alt=""
        />
      </button>

      {/* 프로필 */}
      <button
        className="flex flex-1 items-center justify-center"
        onClick={_onClickProfile}
      >
        <img
          src={
            location.pathname === "/my-page"
              ? images.bottomTab.profile.active
              : images.bottomTab.profile.inactive
          }
          className="w-8 h-8"
          alt=""
        />
      </button>
    </div>
  );
};

export default BottomTab;
