import { init } from "@amplitude/analytics-browser";
import Hotjar from "@hotjar/browser";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/login";
import { Main } from "./pages/main";
import { MyPage } from "./pages/my-page";
import { Address } from "./pages/order/address";
import { Basket } from "./pages/order/basket";
import { Order } from "./pages/order/bill";
import { Finish } from "./pages/order/finish";
import { Product } from "./pages/product";
import { Signup } from "./pages/sign-up";

function AppRedirection() {
  useEffect(() => {
    window.location.href = "greenhada://";

    let qs = queryString.parse(window.location.search);
    if (qs.utm_source === undefined) {
      qs.utm_source = "landing";
    }
    qs.utm_content = "landing";
    let utmTag =
      Object.keys(qs).length === 0 ? "" : "?" + queryString.stringify(qs);

    const downloadIOS = () => {
      window.location.href =
        "https://apps.apple.com/kr/app/%EA%B7%B8%EB%A6%B0%ED%95%98%EB%8B%A4-%EC%B4%88%EC%A0%80%EA%B0%80-%EB%86%8D%EC%82%B0%EB%AC%BC-%EB%8F%84%EB%A7%A4-%EC%96%B4%ED%94%8C/id6468979011" +
        utmTag;
    };

    const downloadAOS = () => {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.postalpine.greenhada&" +
        utmTag.slice(1, utmTag.length);
    };

    const downloadApp = () => {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        downloadIOS();
      } else {
        downloadAOS();
      }
    };

    setTimeout(() => {
      downloadApp();
    }, 1000);
  }, []);
  return (
    <div className="mt-[50px] justify-center text-center font-bold text-[24px]">
      <a href="greenhada://">그린하다 앱으로 이동하기</a>
    </div>
  );
}

function App() {
  if (process.env.NODE_ENV !== "development") {
    init("be9b8b281c4f177f748eb113dfa6cc68");
    Hotjar.init(3765029, 6);
    ReactPixel.init("250070420947707");
    Sentry.init({
      dsn: "https://383f65dfbd370a8082d28a354eaa5b38@o623955.ingest.sentry.io/4506397617553408",
      integrations: [new Sentry.Replay()],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  useEffect(() => {
    alert(
      "서비스 종료 안내\n2024년 3월 18일자로 그린하다 서비스를 종료하게 되었습니다.\n그동안 이용해 주신 모든 고객님들께 감사드립니다."
    );
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/app" element={<AppRedirection />} />
      <Route path="/product" element={<Product />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/my-page" element={<MyPage />} />
      <Route path="/basket" element={<Basket />} />
      <Route path="/order" element={<Order />} />
      <Route path="/order/address" element={<Address />} />
      <Route path="/order/finish" element={<Finish />} />
    </Routes>
  );
}

export default App;
