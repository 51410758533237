import { ClipLoader } from "react-spinners";

export const Loader = () => {
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center"
      style={{ zIndex: 100 }}
    >
      <ClipLoader size={50} />
    </div>
  );
};
