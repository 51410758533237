import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api/auth";
import { images } from "../common/images";
import { LogoHeader } from "../components/headers";
import MainLayout from "../layouts";

export const Login = () => {
  const navigate = useNavigate();

  const session = window.sessionStorage;

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (username.includes("@") && password.length >= 8) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [username, password]);

  const _login = async () => {
    setButtonDisabled(true);

    const data = await login({ username, password });
    if (data) {
      console.log(data);
      session.setItem("token", data.token);
      navigate("/");
    }

    setButtonDisabled(false);
  };

  const _findAccount = () => {
    window.open("https://pf.kakao.com/_NZcuG/chat");
  };

  const _signup = () => {
    navigate("/sign-up");
  };

  return (
    <MainLayout>
      <LogoHeader />
      <div className="px-[20px] pt-[32px] pb-[60px] bg-white h-full">
        <div className="flex flex-col gap-y-[10px] mb-[16px]">
          <input
            className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
            placeholder="이메일 주소"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="email"
          />
          <input
            className="bg-gray-5 h-[52px] w-full px-[16px] rounded-[8px] text-[16px] placeholder:text-gray-40 outline-none"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                _login();
              }
            }}
          />
        </div>
        <button
          className="text-gray-40 float-right mb-[36px]"
          onClick={_findAccount}
        >
          아이디/비밀번호 찾기
        </button>

        <button
          className={`w-full h-[48px] rounded-[8px] mb-[32px] ${
            buttonDisabled
              ? "bg-gray-20 text-gray-40"
              : "bg-green-50 text-white"
          }`}
          onClick={_login}
        >
          로그인
        </button>

        <div className="text-center text-[14px] text-gray-40 mb-[8px]">
          지금 가입하고, 인기상품을 100원에 구매하세요!
        </div>

        <div className="flex justify-center">
          <button className="flex items-center text-[14px]" onClick={_signup}>
            회원가입
            <img src={images.chevron.right.black} className="w-4 h-4" alt="" />
          </button>
        </div>
      </div>
    </MainLayout>
  );
};
